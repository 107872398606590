<template>
  <div class="mx-auto col-11 mt-5">
    <div class="row col-12 flex flexLeft">
      <p class="h4 text-center fw-3">{{apptype.year}}年度 {{apptype.name}} - 報告</p>
    </div>
    <hr class="title">


    <div class="mt-4 p-2 py-2">
      <div
        v-for="(field, index) in fields" :key="index">
        <b-row class="mt-2 mx-4" py-2>
          <div class="label pl-0 pr-1 mb-0 fw-6 flex flexCeil">
            <label class="mb-0 mt-1">{{field.label}}</label>
          </div>
          <div class="mainWidth">
            <div
              v-for="(option, index) in field.options" :key="index">
              <!-- テキスト -->
              <div
                class="my-1"
                v-if="option.type === 1">
                {{option.body}}
              </div>
              <!-- ファイル -->
              <div
                class="my-1"
                v-if="option.type === 2">
                {{option.prefix}}:
                <b-link
                @click="downloadFile(option.reptype_asset_id, option.body)">
                  {{option.body}}
                </b-link>
                {{option.suffix}}
              </div>
              <!-- 申請期間 -->
              <div
                class="my-1"
                v-if="option.type === 3">
                {{viewReportRimitDatetime}}
              </div>
              <!-- メッセージ -->
              <div
                v-for="(msg, index) in option.msgs" :key="index"
                :class="`msg_${msg.view_template}`">
                {{msg.message}}
              </div>
            </div>
          </div>
        </b-row>
        <hr>
      </div>
    </div>

    <div
      class="innerItem mt-4 mb-4">
      <div>
        <b-link class="underline" v-b-modal.PrivacyPolicyShow>個人情報の取り扱いについて</b-link>
      </div>
      <b-form-checkbox
        v-if="canApplicate && formStatus === formStatuses.INIT"
        size="lg"
        class="mt-2"
        v-model="isAgreed"
        switches>
        <span
          class="f-16 notice" v-if="canApplicate && formStatus === formStatuses.INIT">
          上記の個人情報取り扱い事項について同意します。</span>
      </b-form-checkbox>
    </div>

    <div
      v-if="(!withIn && !canGoForm) || !canApplicate"
      class="innerItem mt-4 error">
      <p v-if="!canGoForm && !withIn">報告期間外のため、報告はできません。</p>
    </div>

    <div class="bottonArea mt-3 mb-3 flex flexCenter">
      <b-button class="btn btn-lg mr-4 bold" to="/top">トップに戻る</b-button>
      <!-- init -->
      <b-button
        variant="primary"
        v-if="formStatus === formStatuses.INIT && canApplicate"
        :disabled="!isAgreed"
        class="btn btn-primary btn-lg mr-4 bold"
        :to="`/report/form/${reptype.id}`"
        >入力ページへ</b-button>
        <!-- edit -->
      <b-button
        variant="primary"
        v-if="formStatus === formStatuses.EDIT && canApplicate"
        class="btn btn-primary btn-lg mr-4 flex flexMiddle bold"
        :to="`/report/form/${reptype.id}`"
        >編集する</b-button>
        <!-- watch -->
      <b-button
        variant="primary"
        v-if="formStatus === formStatuses.VIEW && canApplicate"
        class="btn btn-primary btn-lg mr-4 bold"
        :to="`/report/form/${reptype.id}`"
        >閲覧する</b-button>
    </div>
    <!-- popUp -->
    <v-pop-privacy-policy></v-pop-privacy-policy>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import privacyPolicyModal from '@/components/parts/PrivacyPolicyModal.vue';
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'gasReport',
  components: {
    'v-pop-privacy-policy': privacyPolicyModal,
  },
  data() {
    return {
      apptype: {},
      reptype: {
        id: null,
        year: null,
        name: '',
        open_datetime: null,
        close_datetime: null,
        result_announcement_datetime: null,
        assets: {},
      },
      isAgreed: false,
      status: null,
      canApplicate: true,
      formStatus: null,
      fields: {},
    };
  },
  computed: {
    viewReportRimitDatetime() {
      if (!this.reptype.open_datetime || !this.reptype.close_datetime) {
        return '未定';
      }
      return `${moment(this.reptype.open_datetime).format('YYYY年MM月DD日 HH時mm分')} ~ ${moment(this.reptype.close_datetime).format('YYYY年MM月DD日 HH時mm分')}`;
    },
    viewOpenDatetime() {
      if (!this.reptype.open_datetime) {
        return '';
      }
      return moment(this.reptype.open_datetime).format('YYYY年MM月DD日 HH時mm分');
    },
    viewCloseDatetime() {
      if (!this.reptype.close_datetime) {
        return '';
      }
      return moment(this.reptype.close_datetime).format('YYYY年MM月DD日 HH時mm分');
    },
    viewResultAnnouncementDatetime() {
      return moment(this.reptype.result_announcement_datetime).format('YYYY年MM月DD日 HH時mm分');
    },
    withIn() {
      const now = moment();
      const okFrom = now.isSameOrAfter(this.reptype.open_datetime);
      const okTo = now.isBefore(this.reptype.close_datetime);
      return okFrom && okTo;
    },
    canEdit() {
      // 差戻の場合はいつでも編集可能
      if (this.status === CONST_STATUS.APP_STATUS.RETURN) {
        return true;
      }
      // 提出済みと一時保存の場合は期間内のみ編集可能
      const isSubmit = this.status === CONST_STATUS.APP_STATUS.SUBMIT;
      const isTempsave = this.status === CONST_STATUS.APP_STATUS.TEMPSAVE;
      if ((isSubmit || isTempsave) && this.withIn) {
        return true;
      }
      return false;
    },
    canWatch() {
      // 提出済みかつ編集不可の時
      return this.status && !this.canEdit;
    },
    formStatuses() {
      return CONST_STATUS.FROM_STATUS;
    },
    canGoForm() {
      const isInit = this.formStatus === this.formStatuses.INIT;
      const isEdit = this.formStatus === this.formStatuses.EDIT;
      return isInit || isEdit;
    },
  },
  methods: {
    async fetchreptypeBaseDate(reptypeId) {
      const param = {
        reptypeId,
      };
      const promiseFuncs = [
        api.send('/api/report/base', param),
        api.send('/api/report/myrep', param),
      ];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.error(err);
          return false;
        });
      const { base, fields } = responses[0].data;
      if (!base) {
        this.$router.push({ name: 'gasTop' });
      }
      this.fields = fields;
      this.status = responses[1].data.status;
      this.canApplicate = responses[1].data.canApplicate;
      this.formStatus = responses[1].data.formStatus;
      Object.assign(this.reptype, base);
      this.apptype = this.reptype.app_type;
    },
    async downloadFile(assetId, assetName) {
      const param = {
        reptypeId: this.reptype.id,
        assetId,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/report/download/file', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    const { reptypeId } = this.$route.params;
    await this.fetchreptypeBaseDate(reptypeId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>

  .innerItem {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .head {
    background-color: #122889;
    width: 5px;
    height: 20px;
  }

  .titleWrap {
    padding: 8px 5px 8px 10px;
    border: 1px solid #ddd;
  }
  .head {
    background-color: #122889;
    width: 5px;
    height: 22px;
  }
  .title {
    font-size: 126%;
    line-height: 1.2em;
  }
  .reptypeData {
    font-size: 1.1rem;
  }

  .error {
    color: #dc3545;
  }

  .underline {
    text-decoration: underline;
  }

  .notice {
    vertical-align: middle;
  }

  .fileLabel {
    display: inline-block;
    /* width: 70px; */
  }

  .label {
    width: 180px;
  }

  .mainWidth {
    width: 890px;
    max-width: 890px;
  }

  .msg_1 {
    margin-left: 15px;
    font-size: 13px;
  }

  .msg_3 {
    margin-left: 67px;
  }
</style>
